
import { Component, Vue, Provide, Emit, Prop, Watch } from 'vue-property-decorator'
import { mobileRegExp } from '@/utils/reg'
import InputItem from '@/components/InputItem.vue'
import GlobalMixin from '@/mixins/global'
import smsMixin from '@/mixins/sms'
import httpHelper from '@/utils/request'
import randomString from '@/utils/random'
import { parseSearch } from '@/utils/env'
import { setUnifyChannel } from '@/utils/cookies'
const CryptoJS = require('crypto-js')

@Component({
  name: 'passportBindPhone',
  components: {
    'input-item': InputItem
  },
  mixins: [GlobalMixin, smsMixin]
})
export default class PassportBindPhonePage extends Vue {
  @Provide() public isFirst: boolean = true
  @Provide() public mobile: string = ''
  @Provide() public apiError: string = ''
  @Provide() public smsType: number = 1
  @Provide() public code: string = ''
  @Provide() public codeArr: any = []
  @Provide() public errorMsg: string = ''
  @Provide() public lastMobile: string = ''
  @Provide() public loding: boolean = false

  public created () {
  }
  @Emit()
  public async sendCode () {
    this.apiError = this.mobileError()
    if (this.apiError) {
      return
    }
    let mobile = this.mobile
    let isSecend = 0
    if (this.lastMobile === mobile) {
      isSecend = 1
    }
    this.lastMobile = mobile
    const queryStrings = parseSearch(location.search)
    const channel = queryStrings.channel || ''
    const [err, res] = await this.sendSMSCode(mobile, this.smsType, isSecend, channel)
    if (err && err.code && err.msg) {
      this.errorMsg = err.msg
    } else {
      this.$toasted.show('验证码获取成功！')
    }
    this.isFirst = false
  }
  @Emit()
  public mobileError () {
    if (!this.mobile) {
      return '手机号码不能为空'
    }
    return mobileRegExp.test(this.mobile) ? '' : '手机号格式不正确，请重新输入'
  }

  @Emit()
  public goback () {
    if (!this.isFirst) {
      this.isFirst = true
      this.errorMsg = ''
      this.apiError = ''
      this.code = ''
      this.codeArr = []
    } else {
      history.back()
    }
  }

  @Emit()
  public setValue (detail: any) {
    this.mobile = detail.value
  }

  @Emit()
  public async input () {
    await this.login()
  }
  @Emit()
  public async login () {
    const queryStrings = parseSearch(location.search)
    this.codeArr = String(this.code).split('') || []
    if (this.codeArr.length === 6) {
      if (this.loding) {
        return false
      }
      this.loding = true
      const parameter:any = {
        phone: this.mobile,
        smsCode: this.code
      }
      const data:any = {
        grant_type: 'Auth_Unify',
        phone: this.mobile,
        smsCode: this.code,
        ...queryStrings
      }
      let iv = randomString(16)
      const time = Math.round(Date.now() / 1000)
      const message = `${this.mobile}|${time}`
      const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
      data.salt = iv
      iv = CryptoJS.enc.Utf8.parse(iv)
      const encrypted = CryptoJS.AES.encrypt(message,
        key,
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        })
      data.phone = encrypted.toString()
      try {
        const res = await httpHelper.post({
          contentType: 'application/x-www-form-urlencoded',
          url: 'authorize/unifyBind',
          data
        })
        this.errorMsg = ''
        this.fetchLogin(res)
      } catch (err) {
        this.code = ''
        this.codeArr = []
        this.fetchLogin(err)
        this.errorMsg = err.msg
      }
      this.loding = false
    }
  }

  private fetchLogin (res: any) {
    const queryStrings = parseSearch(location.search)
    const result = res.value || {}
    switch (res.code) {
      case 0:
        let url = result.extend.redirectUrl
        location.replace(url)
        break
      case 105:
        setUnifyChannel(queryStrings.channel)
        location.replace(`/b/explain`)
        break
    }
  }
}
